/*----------| Fonts |---------*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,400i,500,600,700,800,900,900i&display=swap');
@import './utils';

* {
  box-sizing: border-box;
}

html,
body {
  @extend %theme-font;

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  // background-color: $theme-background;
  color: $theme-black;
  font-size: 16px;
}

/*----------| GloBal Classes |---------*/
.action-color {
  padding: 7px 30px;
  border-radius: 0;
  font-size: 15px;
  background: #eea904;
  color: #0e2240;
  text-transform: uppercase;
}
a {
  text-decoration: none;
  cursor: pointer;
}
.border-bottom {
  border-bottom: 1px solid #ddd;
  padding: 0 0 15px;
  margin: 0 0 15px;
}

/*----------| Form |---------*/
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn.active.focus,
a:focus {
  outline: 0 none;
  box-shadow: none;
}
.btn {
  margin-bottom: 5px;
}
td .btn {
  margin-bottom: 0px;
}
.text-right .btn + .btn,
td .btn + .btn {
  margin-left: 5px;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: 0;
}
.btn.no-radius {
  border-radius: 0;
}

.form-control {
  border-radius: 1px;
  border-width: 1px;
  box-shadow: none;
  /*padding: 20px 10px;*/
  height: 43px;
  font-size: 14px;

  -webkit-border-radius: 1px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.yellow-btn {
  background: #eea904;
  color: #0e2240;
  text-align: center;
  border: none;
  font-weight: 400;
  border-radius: 2px;
  padding: 7px 20px;
}

.yellow-btn:hover,
.yellow-btn:visited,
.yellow-btn:focus,
.yellow-btn:active,
.yellow-btn:active:focus {
  background: #d5990b;
  color: #0e2240;
}

.orange-btn {
  background: #eca820;
  color: #0e2240;
  text-align: center;
  border: none;
  font-weight: 400;
}
.orange-btn:hover,
.orange-btn:visited,
.orange-btn:focus,
.orange-btn:active,
.orange-btn:active:focus {
  background: #eca820;
  color: #0e2240;
}

.btn-sm.yellow-btn {
  padding: 5px 10px;
}
.max-size {
  line-height: 22px;
  height: 32px
}
.sect-btn {
  background: #bbbbbb;
  border: 0;
}
.textarea {
  resize: none;
}
.form-group .radioGrp > div {
  display: inline-block;
  margin-right: 7%;
}
.form-group .radioGrp > div .radio {
  margin: 0;
}

/*----------| Pagination |---------*/
.data-table-pagination {
  text-align: right;
}
.data-table-pagination .pagination {
  margin: 0;
}

/*----------| BG Color |---------*/
.secd-color {
  color: #eea904;
}
.prime-color {
  color: #0e2240;
}
.bg-grey,
.bg-red,
.bg-orange,
.bg-yellow,
.bg-red1,
.bg-green {
  color: #fff;
  position: relative;
  top: -23px;
  bottom: 20px;
  border-radius: 5px;
  left: 15px;
  box-shadow: 0px 3px 10px 0px rgba(14, 34, 61, 0.19);
  text-shadow: 0px 3px 10px rgba(14, 34, 61, 0.19);
}
.bg-grey {
  background-color: #7b899e;
}
.bg-red {
  background-color: #00b8cc;
}
.bg-orange {
  background-color: #ff8f21;
}
.bg-yellow {
  background-color: #eed114;
}
.bg-red1 {
}
.bg-green {
  background-color: #3ea659;
}

/*----------| Margins |---------*/
.md-padd0 {
  padding: 0;
}
.mrg-b0 {
  margin-bottom: 0px;
}
.mrg-b5 {
  margin-bottom: 5px;
}
.mrg-b10 {
  margin-bottom: 10px;
}
.mrg-b15 {
  margin-bottom: 15px;
}
.mrg-t10 {
  margin-top: 10px;
}
.mrg-t15 {
  margin-top: 15px;
}
.mrg-t30 {
  margin-top: 30px;
}
.mrg-t40 {
  margin-top: 40px;
}
.mrg-r15 {
  margin-right: 15px;
}
.mrg-l5 {
  margin-left: 5px;
}
.mrg-l10 {
  margin-left: 10px;
}
.mrg-l15 {
  margin-left: 15px;
}
.mrg-l30 {
  margin-left: 30px;
}
.padd-t5 {
  padding-top: 5px;
}
.padd-t8 {
  padding-top: 8px;
}
.padd-l15 {
  padding-left: 15px;
}
.padd-l30 {
  padding-left: 30px;
}
.padd-l50 {
  padding-left: 50px;
}
.padd-l70 {
  padding-left: 70px;
}

/*----------| Headings |---------*/
h1 {
  font-size: 3rem;
  /* color: $theme-black; */
  position: relative;
  margin: 0 0 10px;
  padding-bottom: 10px;
  overflow: hidden;
}
h2 {
  /* color: $theme-black; */
  text-align: left;
  text-transform: none;
  padding: 10px 0;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
}

.content-heading > button {
  float: right;
  margin-bottom: 0;
}
.orange-pannle h3 {
  font-size: 1.7rem;
  margin: 0;
  color: #fff;
}
h4 {
  font-size: 1.4rem;
}

.profile-container {
  padding: 20px;
}

/*----------| Table |---------*/
.table-responsive {
  border: 0;
}

.table tbody tr td {
  padding: 10px;
}


table.table-user-information .table > tbody > tr > td:first-child {
  border-top: 0;
}

.full-content input[type='text'],
.full-content input[type='number'] {
  width: 100%;
}

table.table-user-information {
  margin-bottom: 0;
}

table.table-user-information .table > tbody > tr > td:first-child {
  border-top: 0;
}

.table-bordered {
  border: 1px solid #ddd;
  display: table;
  width: 100%;
  margin-top: 20px;
}

.table thead tr th {
  white-space: nowrap;
}

.data-table-grid {
  margin-bottom: 18px;
  width: 100%;
  max-width: 100%;
  margin-top: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  background-color: #fff;

  .text-nowrap tr th {
    cursor: pointer;
  }

  tr {
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        max-width: 400px;
      }
    }
  }
}

/*----------| Header |---------*/
header.dashbaord-header {
  background: #ffffff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 3;
}

.dashbaord-logo {
  float: left;
  background: #0e2240;
  text-align: center;
  width: 240px;
}
.dashbaord-logo img {
  width: 210px;
  margin: 0 auto;
}
.dashbaord-logo-tab {
  display: none;
}
/* header p {color: #fff; padding: 15px 0; text-transform: uppercase; text-align: right;} */
.header-right {
  float: left;
  width: 100%;
  padding: 0 10px;
}
.header-right .navbar-login {
  padding: 10px;
  padding-bottom: 0px;
  font-weight: 300;
}
.header-right .navbar-login-session {
  padding: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.navbar-right {
  float: right;
  padding: 0 20px 0 15px;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background: none;
}
.header-right .navbar-right a {
  color: #4c5e70;
  font-size: 1rem;
}

.navbar-right .user-name {
  display: inline-bloc;
  padding: 11px 10px;
  vertical-align: middle;
}
.header-right span.proicon {
  display: inline-block;
  vertical-align: middle;
}
.header-right span.proicon img {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.profile-pic {
  border: 1px solid #fff;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.header-right .navbar-right .dropdown-menu {
  font-weight: 300;
  color: #fff;
  width: 190px;
}
.header-right .navbar-right .dropdown-menu li {
  border-bottom: 1px solid #f3f3f3;
}
.header-right .navbar-right .dropdown-menu li:last-child {
  border: 0;
}
.header-right .navbar-right .dropdown-menu a {
  color: #0e2240;
  padding: 7px 15px;
}
.header-right .nav > li.dropdown > a:focus,
.header-right .nav > li.dropdown > a:hover {
  background: none;
}

/*----------| Left Menu |---------*/
.content-padded {
  padding: 200px 0 0 0;
}

.right-con-box {
  padding: 10px 0;
}

/*----------| Padding Box Content |---------*/
.padding-box-content {
  position: relative;
  padding: 4rem 1.1rem 0.9rem;
  margin-top: 30px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}
.orange-pannle {
  padding: 1rem 2rem;
  position: absolute;
  top: -22px;
  right: 0;
  background-color: #eca820;
  border-radius: 7px;
  width: 92%;
  left: 4%;
}
.productCategory {
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 30px;
  padding-bottom: 15px;
}
.productCategory:last-child {
  border: 0;
  margin-bottom: 0;
}
.category-list-box {
}

.info-box {
  padding: 20px;
  background: #fff;
  width: 100%;
  border-radius: 2px;
  margin: 25px 0;
  font-weight: 400;

  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    flex: 0 0 auto;
    font-size: 50px;
    padding: 0 30px 0 10px;
    color: $theme-color-dark;
  }

  div {
    flex: 1 1 auto;
  }
}

.info-box-icon {
  border-radius: 50%;
  display: block;
  float: left;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 71px;
  border: 3px solid #fff;
  background: #fff;
  box-shadow: 0 0 0px 3px #eca820;
  overflow: hidden;
}
/* .info-box-content { padding: 5px 10px 5px 120px; background: #e8e8e8;} */
.info-box-content {
  padding: 5px 10px 5px 92px;
  background: #e8e8e8;
}
.info-box-text {
  font-size: 1.2rem;
}
.order-product-icon img {
  height: 50px;
  width: 50px;
}
.order-summ > thead > tr > th,
.order-summ > tbody > tr > th,
.order-summ > thead > tr > td,
.order-summ > tbody > tr > td {
  vertical-align: middle;
}
/* .info-box-description{clear: left; padding: 5px 10px; color: #272727;
overflow-y: auto; width: 100%; float: left; max-height:70px;height:70px; margin-top: -20px;} */

.info-box-description {
  clear: left;
  padding: 5px 10px;
  color: #272727;
  overflow-y: auto;
  width: 100%;
  margin-top: -20px;
  /* float: none; */
  max-height: 100%;
  height: 100%;
  min-height: 70px;
  text-align: justify;
}
/* .info-box-footer {height: 35px;width: 100%;padding: 5px 10px;} */
/* .info-box-footer a.btn{margin-top: 5px;} */
.info-box-footer.btn {
  position: absolute;
  right: 25px;
  bottom: 25px;
}
.infoVids,
.infoImg,
.productCategory div iframe {
  width: 100%;
  max-height: 172px;
  text-align: center;
  background: #ddd;
  margin-top: 23px;
}

.infoImg > img {
  max-width: 100%;
  max-height: 172px;
  cursor: pointer;
}
.productCategory > div iframe {
  max-height: 172px;
}
.productCategory > a {
  float: right;
  margin: 8px 15px 0 0;
}

/* .bg-orange > .imageloader img, .bg-red1 > .imageloader img {width: 90px; height: 82px;} */
.bg-orange > .imageloader img,
.bg-red1 > .imageloader img {
  width: 70px;
  height: 70px;
}
.well.catPro .btn {
  margin-top: 25px;
  margin-bottom: 0;
}
.well.catPro .form-group {
  margin-bottom: 0;
}
.well {
  background-color: transparent !important;
}

/*----------| Payment |---------*/
#save-card + label {
  padding: 0 0 0 5px;
}

.position-right-bottom {
  position: absolute;
  right: 14px;
  bottom: 5px;
}
.position-bottom-link {
  bottom: 0px;
  position: absolute;
  right: 5px;
}

.posotion-relative {
  position: relative !important;
}

/*----------| Profile Page |---------*/
.profile {
  display: flex;
  align-items: center;
}
.profileImg {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  margin-bottom: 7px;
  margin-top: 2px;
  border: 1px solid #ddd;
  overflow: hidden;
  align-items: center;
  display: flex;
}
div.my_profile_image {
  position: relative;
  overflow: hidden;
}

div.my_profile_image input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
}

.headerprofileImg {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  border: 1px solid #ddd;
  overflow: hidden;
  align-items: center;
  display: flex;
  margin: 5px;
}

.headerprofileImg img {
  max-width: 100%;
  min-width: 40px;
  min-height: 40px;
}

.orgProfileImg {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 1px solid #ddd;
  overflow: hidden;
  // align-items: center;
  display: flex;
  // margin: 5px;

  img {
    max-width: 100%;
    min-width: 20px;
    min-height: 20px;
  }
}

.padding-profileImg {
  // padding: 11.5px 15px 6.5px 15px !important;
}
/*.profileImg .my_profile_image:focus, .profileImg .my_profile_image:active {outline: 0;}*/

.profileImg img {
  max-width: 100%;
  min-width: 41px;
  min-height: 41px;
}
.profileInfo {
  width: calc(100% - 150px);
  margin-left: 50px;
  float: left;
}
.profileInfo label {
  width: 40%;
  float: left;
}
.profileInfo .info {
  width: 60%;
  float: left;
  margin-bottom: 10px;
}

.separador-vertical {
  border-right: 1px solid #bfbdbd;
}
.separador-vertical .center-block img {
  width: 100%;
}
.separador-vertical img {
  border-radius: 50%;
}
.separador-vertical {
  border-right: 1px solid #bfbdbd;
  height: 142px;
  align-items: center;
  display: flex;
}
.separador-vertical img {
  border-radius: 50%;
}

/*----------| Solution Area |---------*/
.solution-area {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.49);
  border-radius: 8px;
  padding: 1rem;
  min-height: 150px;
}

.solution-area > a div {
  height: 100px;
  margin: 0 auto;
  width: 100px;
}
.solution-area > a .chalice1 {
  background: url(/assets/images/chalice1.png) no-repeat;
}
.solution-area > a:hover .chalice1 {
  background: url(/assets/images/chalice-hover.png) no-repeat;
}
.solution-area > a .chalice2 {
  background: url(/assets/images/chalice2.png) no-repeat;
}
.solution-area > a:hover .chalice2 {
  background: url(/assets/images/chalice2-hover.png) no-repeat;
}
.solution-area > a .chalice3 {
  background: url(/assets/images/chalice3.png) no-repeat;
}
.solution-area > a:hover .chalice3 {
  background: url(/assets/images/chalice3-hover.png) no-repeat;
}
.solution-area > a .chalice4 {
  background: url(/assets/images/chalice4.png) no-repeat;
}
.solution-area > a:hover .chalice4 {
  background: url(/assets/images/chalice4-hover.png) no-repeat;
}

.solution-area > a div {
  height: 100px;
  margin: 0 auto;
  width: 100px;
  display: flex;
  align-items: center;
}
.solution-area > a .chalice1 {
  background: url(/assets/images/chalice1.png) no-repeat;
}
.solution-area > a:hover .chalice1 {
  background: url(/assets/images/chalice-hover.png) no-repeat;
}
.solution-area > a .chalice2 {
  background: url(/assets/images/chalice2.png) no-repeat;
}
.solution-area > a:hover .chalice2 {
  background: url(/assets/images/chalice2-hover.png) no-repeat;
}
.solution-area > a .chalice3 {
  background: url(/assets/images/chalice3.png) no-repeat;
}
.solution-area > a:hover .chalice3 {
  background: url(/assets/images/chalice3-hover.png) no-repeat;
}
.solution-area > a .chalice4 {
  background: url(/assets/images/chalice4.png) no-repeat;
}
.solution-area > a:hover .chalice4 {
  background: url(/assets/images/chalice4-hover.png) no-repeat;
}

/*----------| Error MSG |---------*/
.errorWrap {
  position: relative;
  width: 80%;
  margin: 0 auto;
  max-width: 1030px;
}
.errorSection {
  position: relative;
  background: #fff;
  width: 100%;
  top: -90px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.36);
  padding: 15px 10px;
  align-items: center;
  display: flex;
  text-align: center;
  min-height: 150px;
}
.errMsg {
  width: 100%;
  font-size: 1.5rem;
  margin: 10px 0;
}

/*----------| Modal |---------*/
.infoImg .modal .modal-header {
  background: #eca820;
  border-radius: 6px 6px 0 0;
  border: 0;
  text-align: left;
}
.infoImg .modal .modal-header h4 {
  color: #fff;
}
.infoImg .modal .modal-header .close {
  opacity: 0.5;
}
.infoImg .modal img {
  width: 100%;
}
.close {
  color: #000 !important;
}

#root,
#root > div:first-child {
  height: 100%;
}

.foscheck-input {
  display: none;
}
.foscheck label {
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 3px;
}
.foscheck-input:checked + label {
  background: url(/assets/images/check.png) no-repeat #0e2240 center;
}
span.rembr {
  padding-left: 30px;
  width: 200px;
  display: block;
  line-height: 26px;
}
.frgt-text {
  text-align: right;
  font-weight: 300;
}

.notifications-menu {
  padding-top: 15px;
}
#body-wrapper
  > header
  > div.col-sm-10.col-md-9
  > div
  > ul
  > li.dropdown.notifications-menu
  > a
  > i {
  font-size: 19px;
}
.orange-lable {
  background: -webkit-linear-gradient(
    left,
    rgba(250, 87, 80, 63),
    rgba(252, 110, 46, 54)
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    rgba(250, 87, 80, 63),
    rgba(252, 110, 46, 54)
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    rgba(250, 87, 80, 63),
    rgba(252, 110, 46, 54)
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    to right,
    rgba(250, 87, 80, 63),
    rgba(252, 110, 46, 54)
  ); /* Standard syntax (must be last) */
  border-radius: 50%;
  font-size: 10px;
  position: absolute;
  top: 4px;
  left: 22px;
}

.box,
.info-box,
.small-box,
.nav-tabs-custom,
.callout {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  border: 0;
}
.category-list-box {
  height: auto;
}
.margintop15 {
  margin-top: 20px;
}
.category-content-tab > #page_content > .cat_cont_box:nth-child(3) {
  margin-top: 50px;
}
.con-top-30 {
  margin-top: -30px;
}

.login-section .login-content .input-group span.help-block,
.help-block {
  top: 0;
  color: rgb(255, 0, 0) !important;
  position: relative;
  right: inherit;
  float: left;
}

.margin-bottom {
  margin-bottom: 30px;
}
.product_name {
  border: none;
  background: none;
}
.change-pointer {
  cursor: pointer;
}

.reg-login-frm {
  width: 500px;
  max-width: 96%;
  padding: 5px 0 0;
  height: 100%;
  margin: auto;
}
.reg-inner-frm {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #ccc;
  padding: 0 15px;
}
.login-head-sec {
  display: inline-block;
  width: 100%;
}
.login-head-sec h2 {
  color: #333;
  text-align: left;
  font-size: 21px;
  margin-left: 1%;
  border-bottom: 2px solid #333;
  display: inline-block;
  margin-bottom: 16px;
}
.col-sm-12.form-group.btm-sec-cap {
  margin-top: 2%;
}
.btm-sec-cap label {
  width: 10%;
}
.btm-sec-cap .input-group {
  width: 43% !important;
  display: inline-block;
  vertical-align: middle;
}
.foscheck-input:checked + label {
  background: url(/assets/images/check.png) no-repeat #0e2240 center;
}
.left-menu .sidebar-nav li.active {
  text-decoration: none;
  background: #eea904;
}
.product_name {
  border: none;
  background: none;
}
.change-pointer {
  cursor: pointer;
}
#fos1,
.fos1 {
  margin-left: 0px;
  width: 20px;
  height: 18px;
}
.login-btn-right {
  padding-left: 0;
}

div#example_filter {
  display: inline-block;
  width: 50%;
}
div#example_length {
  display: inline-block;
  width: 50%;
}
.dataTables_filter input[type='search'] {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px #ededed;
  height: 29px;
}
.dataTables_length select {
  border: 1px solid #ccc;
  height: 29px;
  border-radius: 5px;
}
div#example_info {
  display: inline-block;
  width: 30%;
}
div#example_paginate {
  display: inline-block;
  width: 70%;
}
a#example_previous {
  border: 1px solid #ccc;
  padding: 6px 10px;
  cursor: pointer;
  color: #000;
}
a.paginate_button {
  border: 1px solid #ccc;
  padding: 6px 10px;
  cursor: pointer;
  color: #000;
}
a#example_previous:hover {
  background-color: #0e2240;
  color: #fff;
  border: 1px solid #0e2240;
}
a.paginate_button:hover {
  background-color: #0e2240;
  color: #fff;
  border: 1px solid #0e2240;
}
a.paginate_button:hover {
  background-color: #0e2240;
  color: #fff;
  border: 1px solid #0e2240;
}
footer.text-center.ftr-btm {
  position: static;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.sub-menu-sec {
  background-color: #6e7683;
}
.top-right-drop-sec {
  margin-bottom: 30px;
  padding: 12px 15px 10px;
  text-align: right;
  border-bottom: 1px solid #d8dbe0;
}
.top-right-drop-sec .dropdown-menu {
  right: 3%;
  left: inherit;
  top: 79%;
  padding: 0;
}
.top-right-drop-sec button.btn.btn-default.dropdown-toggle {
  width: 28%;
}
.top-right-drop-sec .btn .caret {
  margin-top: 9px;
  text-align: right;
  float: right;
}

.top-right-drop-sec .btn-default:hover {
  color: #fff;
  background-color: #0e2240;
}
.top-right-drop-sec .btn-default:active {
  color: #fff;
  background-color: #0e2240;
}
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  color: #fff;
  background-color: #0e2240;
}
.top-right-drop-sec .dropdown-menu > li > a:hover {
  color: #fff;
  background-color: #0e2240;
}
.right-con-box.form-home-sec .full-content.form-group {
  margin-top: 4%;
}
i.fa.fa-pencil.table-edit-btn {
  color: green;
  font-size: 18px;
  margin: auto 15px auto 0;
}
i.fa.fa-times.table-edit-btn {
  color: red;
  font-size: 18px;
}

.solution-area img {
  width: 100px;
}
.right-con-box > .container {
  width: 100%;
}
.right-con-box .col-xs-4 {
  width: 100%;
  display: inline-block;
}
.right-con-box .col-xs-4 > div {
  width: 50%;
  display: inline-block;
}
.right-con-box .col-xs-4 > div:nth-child(2) {
  width: 46%;
  float: right;
  text-align: right;
}
.right-con-box .col-xs-8 {
  width: 100%;
  position: absolute;
  bottom: 1%;
}
.back-sv-btn {
  width: 20%;
  float: right;
}
.back-sv-btn button {
  float: right;
  border-color: #bd8706;
}
.back-sv-btn a input {
  float: right;
  margin-right: 10px;
}
.back-sv-btn a .form-group {
  margin-bottom: 0;
}

.uploaded-image img {
  width: 80px;
}
.card-block {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.main-content {
  position: relative;
  top: 20%;
  left: 2%;
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.margin-right-0 {
  margin-right: -15px !important;
  margin-left: -15px !important;
}
.main-full-container {
  margin-top: 98px;
  padding-top: 30px;
  border-top: 3px solid #f6ae1c;
}
.main-full-container h4 {
  font-size: 24px;
  padding-bottom: 8px;
  border-bottom: 2px solid #ccc;
}
.main-full-container .solution-area,
.bottom-content {
  cursor: inherit;
  width: 70%;
  margin: 20px auto;
  padding: 20px;
}
.bottom-content {
  padding: 8px 0;
}
.select-card-list div {
  background: #e2e4e6;
  border-right: 1px solid #fff;
  padding: 8px 7px;
}
.add-card-section button {
  margin-top: 30px;
  float: right;
}
.success-icon {
  margin-bottom: 20px;
}
.success-icon > a {
  display: inline;
}
.success-icon i {
  font-size: 80px;
  color: #5aa219;
}
.form-group.required .control-label:before {
  content: '*';
  color: red;
}
.total-btn {
  text-align: center;
}
.total-btn span.total {
  font-size: 18px;
  display: block;
}
.total-btn span.submit {
  font-size: 20px;
  display: block;
}
.text-sm {
  font-size: 85%;
}
footer.footer.inner {
  position: inherit;
}
.form-control-checkbox {
  margin: 11px 0 0 !important;
  margin-top: 1px\9;
  line-height: normal;
}
.ElementsApp,
.ElementsApp .InputElement {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.ElementsApp input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc !important;
  border-radius: 4px;
}
.right-space {
  margin: 0 5px;
}
.nav-pills > li {
  padding: 15px;
  width: 24%;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background: none;
  color: #eea904;
}
.category-content-tab .tab-content .tab-pane {
  border: 1px solid #ccc;
  padding: 15px;
}
.input-error {
  color: red !important;
}
.cancel-subscription {
  color: #ff0000;
  font-size: 10px;
  font-weight: 900;
}
.div-color {
  background: rgb(233, 6, 6);
  padding: 5px;
  color: white;
}
.center-box {
  width: 60% !important;
  left: 21%;
}
.role-res span .form-group {
  float: left;
  width: 25%;
}
/* .role-res-content{ text-align: left; padding: 15px;} */
.solution-area > a .cat_title {
  width: 100%;
  align-items: flex-end;
  height: 50px;
  display: grid;
  font-size: 12px;
  padding: 0px 10px;
}

/*----------| Loader |---------*/
.orverlay-loader {
  display: block;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.page-content-loader {
  height: 100vh;
}

.category-content-tab img.orverlay-loader-img {
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 80px);
  height: 60px;
  width: 60px;
}

.payment-model-content {
  position: relative;
  left: calc(25% - 30px);
  height: 175px;
  width: 450px;
}

.payment-model-fade-in.fade.in {
  opacity: 1;
  background: #ddd;
}

.fullpage-loader {
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% + 30px);
  height: 60px;
  width: 60px;
}

.fullpage-content-loader {
  position: relative;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  height: 60px;
  width: 60px;
}

.inline-loader {
  padding: 20px 0;
  position: inline;
  padding-left: 20px;

  img {
    height: 30px;
    width: 30px;
  }
}

/*----------| BreaduCrumb |---------*/
.breadcrumb {
  overflow: hidden;
  background: transparent;
  padding: 0;
  font-size: 1rem;
  margin: 10px 0;
}
.breadcrumb li {
  float: left;
  display: inline-block;
}
.breadcrumb li:nth-child(2) {
  margin-left: 0;
}
.breadcrumb > li + li:before {
  content: '';
  padding: 0;
}
.breadcrumb li a {
  color: #0e2240;
  padding: 8px 18px 10px 30px;
  position: relative;
  display: block;
  float: left;
  text-decoration: none;
}
.breadcrumb li a::after {
  content: ' ';
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 16px solid #0e2240;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  z-index: 2;
}
.breadcrumb li a::before {
  content: ' ';
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 16px solid #f5f5f5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  z-index: 1;
}
.breadcrumb li:first-child a {
  padding-left: 10px;
}
.breadcrumb li:nth-child(1) a {
  background: #cccccc;
}
.breadcrumb li:nth-child(1) a:after {
  border-left-color: #cccccc;
}
.breadcrumb li:nth-child(2) a {
  background: #e6e6e6;
}
.breadcrumb li:nth-child(2) a:after {
  border-left-color: #e6e6e6;
}
.breadcrumb li:nth-child(3) a {
  background: #f2f2f2;
}
.breadcrumb li:nth-child(3) a:after {
  border-left-color: #f2f2f2;
}
.breadcrumb li:nth-child(4) a {
  background: #dbdbdb;
}
.breadcrumb li:nth-child(4) a:after {
  border-left-color: #dbdbdb;
}
.breadcrumb li:nth-child(5) a {
  background: #e6e6e6;
}
.breadcrumb li:nth-child(5) a:after {
  border-left-color: #e6e6e6;
}
.breadcrumb li:nth-child(6) a {
  background: #f2f2f2;
}
.breadcrumb li:nth-child(6) a:after {
  border-left-color: #f2f2f2;
}
.breadcrumb li:nth-child(7) a {
  background: #cccccc;
}
.breadcrumb li:nth-child(7) a:after {
  border-left-color: #cccccc;
}
.breadcrumb li a:hover,
.breadcrumb li.active a {
  background: #0e2240;
  color: #fff;
}
.breadcrumb li a:hover:after,
.breadcrumb li.active a:after {
  border-left-color: #0e2240 !important;
  color: #fff;
}
.btn-w-125 {
  width: 125px;
}

.rdw-editor-wrapper {
  background-color: #ffffff;
}
.rdw-editor-wrapper .rdw-editor-main {
  min-height: 220px;
  height: 200px;
  overflow-y: auto;
}

.page-order.draggable {
  padding: 10px 10px;
  margin-bottom: 5px;
  color: #000;
  cursor: move;
  position: static;
  float: none;
  transition: none;
  transform: none;
  box-shadow: none;
  width: 100%;
  background-color: #ddd;
  border: 1px solid #aaa;
}

.dragged {
  opacity: 0.7;
}

.placeholder {
  opacity: 0.5;
}

.video-container {
  /* position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  padding: 10px 0px;
}

.react-player {
  /* position: absolute;
  top: 0;
  left: 0; */
}

.form-horizontal .user-prof-lbl {
  padding-top: 7px;
}

/* Payment Stepwizard */
/*
.stepwizard-step p {
  margin-top: 10px;
}
.stepwizard-row {
  display: table-row;
}
.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}
.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-order: 0;
}
.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
} */

.checked-square {
  height: 21px;
  position: relative;
  top: -1px;
  padding: 0px;
  /* opacity: 0.5; */
}

.disabled-checked-square {
  height: 21px;
  position: relative;
  top: -4px;
  opacity: 0.2;
}

.error-input {
  border: 1px solid #ff0000 !important;
}

.text-danger {
  color: #ff0000;
  font-size: $theme-font-small;
}

.has-error .form-control {
  border-color: #ff0000;
}

.well-container {
  min-height: 20px;
  padding: 19px;
  // background-color: #ffffff;
  border: none;
}

.my-profile-quick-links {
  padding-left: 0px;
  list-style: none;
}

.my-profile-quick-links li {
  padding: 2px 0px;
}
.my-profile-quick-links li a,
.my-profile-quick-links li a:hover {
  text-decoration: none;
}

.fos1[type='radio'] {
  position: relative;
  top: 4px;
}

.customPanelBox {
  padding-left: 0;
  font-family: 'Montserrat', sans-serif !important;
}

.customPanelBox .panel {
  border: 0;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
}

.customPanelBox .panel-body {
  border: 0 !important;
  background-color: transparent;
  height: 100%;
}

.customPanelBox .panel-heading {
  border: rgba(0, 0, 0, 0.1) solid 0px;
  border-width: 0 0 1px 0;
  padding: 10px 5px;
  background-color: transparent;
}

.customPanelBox .panel-heading.col-sm-6 {
  float: none;
  width: 100%;
}

.customPanelBox .panel-default > .panel-heading {
  color: #000;
  background-color: transparent;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  font-weight: 800;
}

.customPanelBox .panel-default > .panel-heading > .dropdown {
  position: absolute;
  right: 21px;
  top: 5px;
}

.customPanelBox .panel-default > .panel-heading > a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
}

.customPanelBox .panel-default > .panel-heading img {
  background: #fff;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  border-radius: 50%;
}

.customPanelBox .panel-default > .panel-body {
  position: relative;
  /* min-height: 180px; */
}
.customPanelBox .panel-default > .panel-body .videoImageBox {
  float: left;
  margin-right: 15px;
  cursor: pointer;
}
.customPanelBox .panel-default > .panel-body .videoImageBox img {
  max-height: 149px;
  width: 100%;
}
.customPanelBox .panel-default > .panel-body .contentBox {
  /* color: #777; */
  /* text-align: justify; */
}
.customPanelBox .panel-default > .panel-body .readMore {
  position: absolute;
  right: 10px;
  bottom: 5px;
  color: #0e2240;
  font-weight: bold;
  font-size: 13px;
}

#rss-wrapper {
  z-index: 2;
  position: fixed;
  right: 300px;
  width: 0;
  height: 88%;
  margin-right: -302px;
  overflow-y: auto;
  background: #f1f1f1;
  border: 1px solid #ddd;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#wrapper.toggled-2 {
  min-height: calc(100% - 40px);
  width: calc(100% - 240px);
  padding: 86px 15px 40px;
}
#wrapper.toggled-2 #rss-wrapper {
  width: 300px;
}

#wrapper .toggleBtn {
  position: fixed;
  right: 0;
  z-index: 1;
  color: #eca820;
  font-size: 26px;
  border-radius: 4px 0 0 4px;
  border: 1px solid #ddd;
  border-right: 0;
  background: #f1f1f1;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#wrapper .toggleBtn:focus {
  outline: none;
}

#wrapper.toggled-2 .toggleBtn.showBtn {
  right: 297px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.newsBox {
  display: inline-block;
  width: 100%;
  border-bottom: 1px dotted #ccc;
  margin-bottom: 10px;
  padding: 0 10px;
}
.newsBox h5,
.newsBox h5 a {
  font-weight: bold;
  text-decoration: underline;
  color: inherit;
}
.newsBox .newsImgContentBox .videoImageBox,
.newsBox .newsImgContentBox .newsImgBox {
  cursor: pointer;
}
.newsBox .newsImgContentBox .newsImgBox {
  width: 100%;
  float: left;
  margin-right: 10px;
}
.newsBox .newsImgContentBox .newsImgBox img {
  width: 100%;
}
.newsBox .newsImgContentBox .newsContentBox {
  color: #000;
  font-size: 13px;
  text-align: justify;
}

.newsBox .newsImgContentBox .newsContentBox > img {
  width: 100%;
}
.newsBox .dateTimeBox {
  font-weight: bold;
  font-size: 12px;
  float: left;
  width: 100%;
  padding: 5px 0;
  color: #555;
}
.newsBox .dateTimeBox .dateBox {
  margin-right: 15px;
}

.panel-body.rss-feed-content {
  max-height: 75vh;
  overflow-y: auto;
}

.panel.panel-primary.no-border {
  border-color: transparent;
}
.panel-body.rss-feed-content .newsBox h5 a {
  color: #0000ff;
}

.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: ' ';
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

a[href="https://froala.com/wysiwyg-editor"], a[href="https://www.froala.com/wysiwyg-editor?k=u"]
{
  display: none !important;
  position: absolute;
  top: -99999999px;
}

div[class='fr-toolbar fr-desktop fr-top fr-basic fr-sticky-off'],
div[class='fr-toolbar fr-desktop fr-top fr-basic fr-sticky-on'] {
  z-index: auto !important;
  border-top: 1px solid #ccc;
}

.panel,
.well {
  @extend .boxxed;
  border: 0;
}

.panel .panel-heading {
  background-color: transparent;
  font-weight: 600;
  color: $theme-black;
  border: rgba(0, 0, 0, 0.1) solid 0px;
  border-width: 0 0 1px 0;
}

.panel .panel-heading .panel-title {
  color: $theme-black;
}

.checkbox .checkbox-item {
  text-decoration: none;
  color: #0e2240;
}

sup.text-danger {
  font-size: 20px;
  position: relative;
  top: 0px;
}

.dragdrop-target {
  cursor: -webkit-move;
  cursor: move;
  /* cursor: move;
  z-index: 9;
  position: absolute; */
}

.ml-10 {
  margin-left: 10px;
}

.clearfix {
  clear: both;
}

.customPanelBox .panel.no-border {
  border: 0;
  background-color: transparent;
  box-shadow: none;
}

.customPanelBox .panel .panel-body {
  border: 1px solid #0e2240;
  /* background-color: #fff; */
  clear: both;
}

.customPanelBox .panel.no-border .panel-body {
  border: 0;
}

.customPanelBox .panel-default > .panel-heading {
  position: static;
  padding: 15px 15px;
  line-height: 25px;
}

.customPanelBox .panel-default > .panel-heading img {
  width: 22px;
  height: 22px;
}

.customPanelBox .panel-default > .panel-heading > a {
  position: relative;
  top: 2px;
}

.customPanelBox .panel-default .dropdown .btn {
  padding: 2px 9px;
}

.customPanelBox .panel-default > .panel-heading > .dropdown {
  position: absolute;
  right: 18px;
  top: 3px;
}

.rt-btn.btn-circle-angle-down.dropdown-toggle {
  background-color: #fff;
  border: 1px solid #428bca;
  border-radius: 15px !important;
  color: #428bca;
  font-size: 11px;
  height: 24px;
  line-height: 1.42;
  padding: 4px 0;
  text-align: center;
  width: 24px;
}

.user-questions table thead tr th,
.user-questions table tbody tr td {
  padding: 5px 10px 5px 0px;
  vertical-align: top;
}

/*2018-10-04*/
.add-card {
  font-size: 16px;
}
.fa-trash-size {
  font-size: 20px;
}
#myModal label {
  text-align: left;
  padding-bottom: 7px;
}
#myModal .form-group {
  margin-bottom: 5px;
}
#myModal .form-control {
  border-radius: 0;
}
.card-img img {
  margin-right: 5px;
}

.nav-tabs li a {
  padding: 7px 4px;
}

.box-sec {
  background-color: #fff;
  /*border-bottom: 2px solid #d4d7dc;*/
  padding: 15px;
  /*min-height: 431px;*/
  margin: 0px -10px;
}

.box-sec h4 {
  margin-top: 0;
}

.bankRow {
  padding: 8px 0;
  border-bottom: 1px solid #eceef1;
}

.bankRow {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.bankRow .bankRowHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  line-height: 1.67;
  width: 100%;
}
.qboName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #393a3d;
  letter-spacing: 0;
  font-weight: 500;
  font-size: 14px;
  max-width: 95%;
}

.qboNameHeader {
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.headerMessage {
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
  text-align: right;
}

.bankRow .bankRowDetail {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  font-family: Avenir Next forINTUIT, Arial, -apple-system, Helvetica Neue,
    sans-serif;
}

.bankRow .bankRowDetail {
  margin-top: 2px;
  width: 100%;
  height: 32px;
  font-weight: 400;
  line-height: 1.33;
}

.bankRow .description {
  text-align: left;
  font-size: 12px;
  color: #46464c;
  letter-spacing: 0;
}

.bankRow .accountDetails {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  text-align: right;
  letter-spacing: 0.17px;
  font-size: 1.2rem;
  min-width: 15%;
  height: 100%;
  -moz-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  padding-left: 1rem;
}

.bankRow .balance {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  color: #46464c;
  height: 100%;
  -moz-flex: 1 0 53%;
  -ms-flex: 1 0 53%;
  -webkit-flex: 1 0 53%;
  flex: 1 0 53%;
  font-size: 12px;
}
.bankRow .count {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -moz-flex: 1 0 47%;
  -ms-flex: 1 0 47%;
  -webkit-flex: 1 0 47%;
  flex: 1 0 47%;
  font-size: 12px;
  color: #a0a0a0;
  font-style: italic;
  font-weight: 100;
}

.bankRowDetail,
.accountDetails {
  font-size: 12px;
}

.pendingTxns {
  font-weight: 500;
  color: #0077c5;
}

.pl-0 {
  padding-left: 0;
}

.div-blk {
  min-height: 300px;
}

.div-blk.div-qb-bank {
  height: 100%;
  overflow-y: auto;
}

.sl-box-sec .ha-button.no-connector .label {
  font-family: Avenir Next forINTUIT, Arial, -apple-system, Helvetica Neue,
    sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  text-align: right;
  color: #46464c;
}

.sl-box-sec .dateSelector {
  font-size: 15px;
  color: #787878;
  height: 14px;
  outline: none;
  box-shadow: none;
  background: transparent;
}
.sl-box-sec .ids-popover {
  position: absolute;
  min-width: 120px;
  z-index: 1;
  padding: 0px;
  top: 40px;
  left: 22px;
  background: white;
  border: 1px solid #c7c7c7;
  background: #fff;
  outline: 0;
  font-size: 14px;
  box-shadow: 0 3px 0 0 rgba(66, 66, 66, 0.15);
  left: auto;
}
.sl-box-sec .ha-button.no-connector {
  padding: 0px;
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
  margin-top: 2.5px;
  background: transparent;
  height: 14px;
  line-height: 33px;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  min-width: 0;
  -webkit-font-smoothing: inherit;
  color: #393a3d;
  font-family: Avenir Next forINTUIT, Arial, -apple-system, Helvetica Neue,
    sans-serif;
  font-weight: 600;
}

.ha-menu-button-react,
.menu_button_label {
  outline: 0;
}

.sl-box-sec .ha-button.no-connector .label {
  font-family: Avenir Next forINTUIT, Arial, -apple-system, Helvetica Neue,
    sans-serif;
  font-size: px;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  text-align: right;
  color: #46464c;
  padding: 0px;
}

.sl-box-sec .ha-button.no-connector .caret {
  width: 0 !important;
  height: 0 !important;
  vertical-align: middle;
  border-top: 10px solid #000000;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  background-color: transparent;
  border-top-color: #393a3d;
  align-self: center;
  position: relative;
  margin: -1px 4px 0 5px;
  margin-left: 5px;
}

.box-sec .dateSelector {
  font-size: 15px;
  color: #787878;
  float: right;
  height: 14px;
  margin-right: -20px;
  margin-top: -10px;
  outline: none;
  box-shadow: none;
  background: transparent;
}
.box-sec .ids-popover {
  position: absolute;
  min-width: 120px;
  width: 120px;
  z-index: 1;
  padding: 0px;
  top: 30px;
  right: 18px;
  background: white;
  border: 1px solid #c7c7c7;
  background: #fff;
  outline: 0;
  font-size: 14px;
  box-shadow: 0 3px 0 0 rgba(66, 66, 66, 0.15);
  left: auto;
}
.box-sec .ha-button.no-connector {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
  padding-bottom: 35px;
  margin-top: 2.5px;
  background: transparent;
  height: 14px;
  line-height: 33px;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  min-width: 0;
  -webkit-font-smoothing: inherit;
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 0 !important;
  color: #393a3d;
  font-family: Avenir Next forINTUIT, Arial, -apple-system, Helvetica Neue,
    sans-serif;
  font-weight: 600;
}

.box-sec .ha-button.no-connector .label {
  font-family: Avenir Next forINTUIT, Arial, -apple-system, Helvetica Neue,
    sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  text-align: right;
  color: #46464c;
}

.box-sec .ha-button.no-connector .caret {
  width: 0 !important;
  height: 0 !important;
  vertical-align: middle;
  border-top: 6px solid #000000;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  background-color: transparent;
  border-top-color: #393a3d;
  align-self: center;
  position: relative;
  margin: -1px 4px 0 5px;
  margin-left: 5px;
}

.ha-menu-item {
  width: 100%;
  display: block;
  min-height: 32px;
  max-height: 64px;
  padding: 8px 14px 7px;
  overflow: hidden;
  text-align: left;
  cursor: pointer;
  z-index: 999;
  font-weight: 500;
  color: #404040;
}

.ha-menu-item .menu-item-label {
  display: block;
  position: relative;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: clip;
  padding: 0 !important;
  max-height: 2.4em;
}

.ha-menu-item.mobile-menu-item {
  height: 48px;
  font-weight: 400;
}

.ha-menu-item.mobile-menu-item .menu-item-label {
  line-height: 28px;
}

.ha-menu-item:focus {
  outline: 0;
  background: #eceef1;
}

.typeahead-menu .ha-menu-item:focus * {
  color: #108000 !important;
  font-weight: 600;
}

.ha-menu-item:focus:not(.disabled) .menu-item-label {
  color: #108000;
  font-weight: 600;
}

.select-ta-mobile-menu .ha-menu-item:focus:not(.disabled) .menu-item-label {
  color: inherit;
}

.ha-menu-item.separator {
  height: 32px;
  margin-top: 2px;
  padding-top: 5px;
  cursor: auto;
  border-top: 1px solid #dcdcdc;
}

.ha-menu-item.separator:focus,
.ha-menu-item.separator:hover {
  outline: 0;
  background: 0 0;
}

.ha-menu-item.disabled {
  opacity: 0.4;
  cursor: auto;
}

.ha-menu-item.disabled:active,
.ha-menu-item.disabled:focus,
.ha-menu-item.disabled:hover {
  outline: 0;
  color: #404040;
  background: 0 0;
}

.ha-menu-item input {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  padding: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
  border: 0 !important;
}

.ha-menu-item input[type='checkbox']:checked + label:before {
  content: '\F01A';
  white-space: nowrap;
  text-align: center;
  font-family: harmonyicons;
  font-size: 13px;
  line-height: 18px;
  color: #2546ac;
}

.ha-menu-item label {
  position: relative;
  display: inline-block;
  padding-left: 27px;
  cursor: pointer;
  min-height: 20px;
  margin-bottom: 0;
  width: 100%;
  line-height: 20px;
  font-family: Avenir Next forINTUIT, Arial, -apple-system, Helvetica Neue,
    sans-serif;
  font-weight: 500;
  font-size: 12px;
}

.ha-menu-item span.type-ahead-strong,
.ha-select-type-ahead .ha-menu-item span.strong,
.ha-textfield-type-ahead .ha-menu-item span.strong,
.typeahead-base .ha-menu-item span.strong,
body.desktop .ha-select-type-ahead .ha-menu-item span.strong,
body.desktop .ha-textfield-type-ahead .ha-menu-item span.strong {
  font-weight: 700;
}

.ha-menu-item label:before {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  left: 0;
  top: 1px;
  content: '';
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  background-color: #fff;
}

.ha-menu-item.add-new-menu-item,
.ha-menu-item.add-new-menu-item:hover {
  color: #108000 !important;
  background-color: #eceef1;
}

.ha-menu-item.active {
  color: #108000 !important;
  background-color: #eceef1;
  font-weight: 700;
}

.ha-menu-item.add-new-menu-item:focus,
.ha-menu-item.add-new-menu-item:hover:focus {
  outline: 0;
  background: #eceef1;
  color: #fff;
}

.ha-menu-item.add-new-menu-item:focus .hi,
.ha-menu-item.add-new-menu-item:hover:focus .hi {
  color: #fff;
}

.ha-menu-item.add-new-menu-item .hi,
.ha-menu-item.add-new-menu-item:hover .hi {
  margin-right: 5px;
  color: #108000;
  font-size: 13px;
}

.ha-menu-item.add-new-menu-item .hi:active,
.ha-menu-item.add-new-menu-item .hi:hover,
.ha-menu-item.add-new-menu-item .menu-item-label,
.ha-menu-item.add-new-menu-item:hover .hi:active,
.ha-menu-item.add-new-menu-item:hover .hi:hover,
.ha-menu-item.add-new-menu-item:hover .menu-item-label {
  color: #108000;
}

@font-face {
  font-family: harmonyicons;
  src: url(https://assets.intuitcdn.net/fonts/harmonyicons-regular-webfont.eot);
  src: url(https://assets.intuitcdn.net/fonts/harmonyicons-regular-webfont.eot?#iefix)
      format('embedded-opentype'),
    url(https://assets.intuitcdn.net/fonts/harmonyicons-regular-webfont.woff2)
      format('woff2'),
    url(https://assets.intuitcdn.net/fonts/harmonyicons-regular-webfont.woff)
      format('woff'),
    url(https://assets.intuitcdn.net/fonts/harmonyicons-regular-webfont.ttf)
      format('truetype'),
    url(https://assets.intuitcdn.net/fonts/harmonyicons-regular-webfont.svg#harmonyicons-regular-webfontRg)
      format('svg');
  font-weight: 400;
  font-style: normal;
}

.ha-menu *,
.ha-menu:after,
.ha-menu:before {
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.ha-menu {
  display: block;
  margin: 3px 0;
  line-height: 32px;
}

.ha-menu:focus {
  outline: 0;
}

.ha-menu.multiple .ha-menu-item:not(.checked):hover {
  background: 0 0;
  color: #404040;
}

.header-right .content-heading {
  position: relative;
  top: 20px;
  left: 15px;
  font-size: 16px;
  color: #0e2240;
}

.pointer {
  cursor: pointer !important;
}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.h-433 {
  height: 433px;
  min-height: 433px;
}
.mar_top15 {
  margin-top: 15px;
}
.header-list {
  margin-left: 30px;
}

.header-link {
  line-height: 19px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 300;
}

.header-logo {
  max-height: var(--chalice-logo-height);
  width: auto;
  height: auto;
  max-width: 300px;
}

.logo-link > span > img {
  display: inline-block;
}

.navbar-custom {
  font-family: 'Montserrat', sans-serif !important;
  background-color: #fff;
  padding: 20px 10px;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

%integrations {
  a {
    padding: 20px;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    width: 150px;
    overflow: hidden;
  }

  a:hover {
    opacity: 0.75;
  }

  a div {
    padding-top: 10px;
    color: #000;
    text-align: center;
  }

  a.disabled {
    color: #ccc;
  }

  a.disabled img {
    opacity: 0.25;
  }

  a.disabled div {
    color: #ccc;
  }

  &::after {
    clear: both;
  }
}

.integrations-menu {
  @extend %integrations;

  width: 300px;
  margin: 0;
  padding: 0;
}

.integrations-panel {
  @extend %integrations;

  margin: 0;
  padding: 0;
}

.integrations-link {
}

.integrations-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px;
}

.integration-icon {
  margin: 0 auto;
  height: 50px;
  width: auto;
  font-size: 40px;
}

.react-datepicker-popper {
  z-index: 10000;
}

.form-group {
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
      input {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out 0.15s,
          -webkit-box-shadow ease-in-out 0.15s;
        -webkit-transition: border-color ease-in-out 0.15s,
          box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

        border-radius: 1px;
        border-width: 1px;
        box-shadow: none;
        /* padding: 20px 10px; */
        height: 43px;
        font-size: 14px;
      }
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.no-margin-row {
  margin: 0 !important;
}

.five-px-padding-left {
  @media screen and (min-width: 768px) {
    padding-left: 5px !important;
  }
}

.five-px-padding-right {
  @media screen and (min-width: 768px) {
    padding-right: 5px !important;
  }
}

.topright-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 25px;
  z-index: 10000;
  background-color: transparent;
  border: 0;
  font-size: 2rem;
  color: $theme-grey;

  &:hover {
    color: $theme-black;
  }

  &:focus {
    border: 0;
    outline: 1px;
  }
}

.w-100 {
  width: 100%;
}

.center-plans {
  margin-right: auto;
  margin-left: auto;
}

.membership-information {
  .panel {
    position: relative;
  }
}

.current-wrapper {
  top: -5px;
  overflow: hidden;
  height: 80px;
  position: absolute;
  width: 80px;
}

.current {
  font-size: 14px;
  font-weight: 500;
  width: 200px;
  background: #e43;
  position: absolute;
  text-align: center;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  background-color: red;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
  top: 20px;
  left: -70px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-navbar {
  background-color: $theme-white;
  position: fixed;
  z-index: 1000;
  width: 100%;

  .navbar-content {
    padding: 0 10px;

    align-items: center;
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;

    .navbar-main-left,
    .navbar-main-right {
      flex: 1 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .navbar-main-left {
      justify-conent: flex-start;
    }

    .navbar-main-right {
      // justify-conent: flex-end;
    }

    .header-logo {
      height: 50px;
      width: auto;
      margin-right: 20px;
      padding-left: 0;
    }

    .contact-us {
      font-weight: 700 !important;
    }
  }

  .navbar-nav > li {
  }

  .navbar-nav > li.active {
  }

  .navbar-nav > li > a {
    line-height: 19px;
    font-weight: 500;
    font-size: 1.1rem;
    padding: 20px 15px;
    color: $theme-black;

    border: transparent solid 0;
    border-bottom-width: 5px;
    background-color: transparent;
    transition: border 0.25s;
  }

  .navbar-nav > li.active a {
    background-color: transparent !important;
    border: rgba(0, 113, 206, 0.5) solid 0;
    border-bottom-width: 5px;
    background-color: transparent;
  }

  .navbar-nav > li > a:hover {
  }

  .navbar-nav > li.active > a:hover {
  }

  .nav-btn {
    background-color: $theme-color !important;
    border-radius: 20px !important;
    color: white !important;
    font-weight: 600 !important;
    min-width: 100px !important;
    text-align: center !important;
    text-decoration: none !important;
    padding: 10px 15px !important;
    margin: 10px 5px !important;
  }

  .nav-login {
    @extend .nav-btn;
  }

  .nav-join {
    @extend .nav-btn;
    background-color: $theme-gold !important;
  }
}

.display-none {
  display: none !important;
}

.main-slider div.tiles .tiles-carousel .slick-track .slick-slide {
  margin: 0 25px;
  flex: 0 0 280px;
}

.main-slider .tiles .tiles-carousel .slick-track {
  padding-left: 20px;
}

.main-slider div.tiles .tile-description {
  font-size: 1rem;
}

.main-slider div.tiles .tile-name {
  font-weight: 800;
  font-size: 1rem;
  padding: 5px 0;
  margin-bottom: 10px;
}

.px-4 {
  padding: 0 5%;
}

.py-4 {
  padding: 3% 0 0;
}

.group.recommended {
  padding: 2% 0 0;
}

.dot {
  background-color: #f1f1f1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 58%;
  margin-left: -50px;
  border: 1px solid #ccc;
  z-index: 99999;
}

.dot:hover {
  cursor: pointer;
}

.selected-dot {
  background-color: #0e2240;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 58%;
  margin-left: -50px;
  border: 1px solid #ccc;
  z-index: 99999;
}

.btm-text a {
  color: #ccc;
  font-weight: 600;
}

.btm-text {
  padding-top: 20px;
}

.pd-btm {
  padding-bottom: 3%;
}

.button-sp {
  margin-right: 5px;
}

.gen-button {
  margin-top: 2rem;
}

.note-sm {
  font-size: 15px;
  margin-left: 12px;
}

.footer > .row {
  margin-right: 0;
  margin-left: 0;
}
.no-review-heading {
  font-weight: 600;
}

.error-multiselect {
  border-color: red !important;
}

.slug-in-modal {
  margin-top: 15px;
}

/*
.cwp-modal-main-form .login-modal .logo-wrapper{
  padding-top: 0;
  padding-bottom: 10px;
  margin-bottom: 0;
  border-bottom: none;
}
.cwp-modal-main-form .login-modal .logo-wrapper img{
  max-width: 80px;
}
.cwp-modal-main-form form input,
.cwp-modal-main-form form select{
  margin-bottom: 5px;
  height: 33px;
  border-radius: 1px;
}
.cwp-modal-main-form {
  padding: 10px 10px 0 !important;
  overflow-y: auto !important;
}
.cwp-modal-main-form .registration-form form {
  padding: 10px !important;
}
.cwp-modal-main-form h1{
  font-size: 22px;
  padding: 0;
}
.cwp-modal-main-form,
.cwp-modal-main-form *{
  box-sizing: border-box;
}
.cwp-modal-main-form .login-sub{
  padding: 5px 10px;
  border-top: 1px solid #ccc;
  a{
    font-weight: 600;
  }
}
*/

.form-group.lg-area > .form-control.textarea {
  height: 150px;
}

.pro-pic {
  border-radius: 50%;
}

.border-bottom-1 {
  border-bottom: 1px solid black;
}

.custom-shift {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.video-container iframe,
.video-container img {
  max-width: 100%;
}

.previous-claims {
  width: 33%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.length-note {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #a4a4a4;
  padding: 10px 0;
}

.coaltion-form-title {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
}

.coaltion-form-title-small {
  font-size: 20px;
  font-weight: 700;
}

.btn-group {
  width: 100%;

  .btn-default:hover {
    background-color: white;
  }

  .two-buttons {
    width: 50%;
  }

  .three-buttons.right {
    width: 27%;
  }
  .three-buttons.left {
    width: 27%;
  }
  .three-buttons.middle {
    width: 46%;
  }

  .active {
    background-color: $theme-color !important;
    color: white !important;
    font-weight: 600 !important;
  }

  .btn {
    font-weight: 400 !important;
    color: $theme-black;
  }

  .left {
    border-bottom-left-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }

  .right {
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
}

.question-text {
  font-size: 15px;
  font-weight: 500;
}

.get-quote-btn {
  background-color: #1876d1 !important;
}

.policy-cost {
  font-size: 18px;
}

.surcharges {
  font-size: 14px !important;
}

.policy-total {
  border-top: black 1px solid;
}

.policy-header {
  border-bottom: rgba($theme-black, 0.25) 1px solid;
  text-align: center;
  font-size: 18px;
  margin: 0 auto 20px;
  width: 90%;
  padding: 10px 0;
}

.cyber-loader {
  height: 50px;
  width: 50px;
}

.cyber-btn {
  a {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 2px;
  }
  .download-report {
    background-color: $group-buttons-blue;
    color: white;
  }
  :hover {
    background-color: darken($group-buttons-blue, 10%);
  }
}

.return-btn {
  margin: 0 auto;
  max-width: 80% !important;
  color: white;
  text-decoration: none !important;
  color: black;

  a {
    display: inline-block;
    padding: 10px 20px;
    border: solid #ddd 1px;
    text-decoration: none;
    border-radius: 2px;
  }
  :hover {
    background-color: #4cae4c;
  }
}

.coalition-img {
  margin: 0 auto;
  max-width: 50%;
}

.bind-quote {
  margin: 0 auto;
}

.quote-box {
  padding: 5px !important;
  font-size: 12px;
  font-weight: 500;
  h2 {
    font-size: 18px;
  }
}

.company-row:hover {
  cursor: pointer;
}

.recent-quote-header {
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 5px;
}

.pending-quote {
  color: #fac941;
}

.good-quote {
  color: #1eb267;
}

.active-recent-quote {
  background-color: #e9faff !important;
}

.quote-table-cell {
  white-space: nowrap;
  overflow: hidden;
  // text-overflow: ellipsis;
  letter-spacing: -0.7px;
}

.coalition-support {
  font-weight: 500;
  font-size: 1.4em;
}

.percent-change {
  margin-bottom: 10px;
}

.tooltip-style {
  padding: 6px 6px 6px 6px;
  width: 150px;
}

.dashboard-border-radius {
  border-radius: 3px !important;
}

// Header overrides
.hidden-md {
  @media only screen and (max-width: 990px) {
    display: none;
  }
}

.hidden-lg {
  @media only screen and (min-width: 990px) {
    display: none;
  }
}

.nav-container {
  width: 100%;

  @media only screen and (max-width: 990px) {
    display: none;
    height: 500px;
  }
}

.nav-item {
  padding-left: 5px;
}

.dropdown-menu1 {
  position: absolute;
  z-index: 10000;
  background-color: #f7f8fa;
  border: 0;
  top: auto;
  padding: 20px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding-bottom: 0px;

  @media only screen and (max-width: 990px) {
    height: 100%;
    // max-height: calc(100vh - 58px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px;
    z-index: 100;
    width: 100%;
    top: 0px;
    left: 0px;
    :global(.resources) {
      padding: 0 33px;
    }
  }

  a {
    color: #022241;
  }

  svg {
    fill: #022241;
  }
}

.dropdown-menu1.mega-menu1 {
  position: absolute;
  width: 100%;
  border: 0;
  left: 0;
  top: 100px;
  padding: 20px;
}

.return-link {
  @media only screen and (min-width: 991px) {
    display: none;
  }
  @media only screen and (max-width: 990px) {
    a {
      font-size: 18px;
      color: #022241;
      font-weight: 600;
      position: relative;
      padding: 33px 54px;
      display: block;
      border-top: 1px solid #e3e7ed;
      border-bottom: 1px solid #e3e7ed;
    }
    a::before {
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-left: 0.85em;
      vertical-align: 0.255em;
      content: '';
      border-bottom: 2px solid #022241;
      border-left: 2px solid #022241;
      border-top: none;
      border-right: none;
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(45deg);
      position: absolute;
      left: 18px;
      top: 46%;
    }
  }
}

.nav-user-image {
  @media only screen and (max-width: 990px) {
    padding: 0.5rem 0 0.5rem 2rem;
  }
}

.nav-relative {
  @media only screen and (min-width: 991px) {
    position: relative;
  }
}

.nav-image-dropdown {
  @media only screen and (min-width: 991px) {
    width: 200px;
    left: -160px;
  }
}

.nav-link-button {
  display: inline-block;
}

.navbar {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;

  @media only screen and (max-width: 990px) {
    padding: 0;
  }
}

.navbar-collapse {
  margin-left: auto;
  flex-grow: 0;
}

.mega-parent.dropdown {
  position: static;
}

.dropdown {
  @media only screen and (max-width: 990px) {
    display: block;
    width: 100%;
  }
}

.dropdown-animation {
  transition: all 0.25s;
  display: block;
  opacity: 1;
}

.hidemenu {
  transition: all 0.25s;
  transform: translateX(100%);
  opacity: 0;
}

.navbar-nav .nav-link {
  color: #022241;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
  white-space: nowrap;
}

.nav-link {
  display: block;
  padding: 0.5rem 2rem 0.5rem 0;
  @media only screen and (max-width: 990px) {
    padding: 0.5rem 0 0.5rem 2rem;
  }
}

.dropdown-item {
  display: block;
  padding: 0 2rem 0 0;
}

.nav-link span,
.dropdown-item span {
  &:before {
    content: '';
    height: 2px;
    width: auto;
    background-color: #0d70cc;
    display: block;
    position: relative;
    bottom: -22px;
    transform: scaleX(0);
    transform-origin: right center;
    transition: transform 0.5s cubic-bezier(0.694, 0, 0.335, 1);
  }
  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: left center;
    }
  }
}

nav .btn.btn-blue-solid {
  margin-left: 8px;
}

.dropdown-toggle::after {
  display: none;
}

.nav-item.dropdown.simple-dropdown:hover .dropdown-menu1 {
  display: block;
}

.simple-dropdown .dropdown-menu1 {
  background-color: #f7f8fa;
  border: 0;
  margin: 0;
  min-width: 15.7rem;
  left: -50%;
  padding: 35px;
}

.simple-dropdown .dropdown-menu1 .dropdown-item1 {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding-bottom: 10px;
}

.dropdown-item span {
  position: relative;
  width: auto;
  display: inline-block;
  padding-left: 0px !important;
  margin-bottom: 10px;
}

.nav-dropdown-display {
  display: flex;
  align-items: center;

  svg {
    fill: #022241;
    stroke: #022241;
  }
}

.sm-padding {
  @media only screen and (max-width: 990px) {
    padding-bottom: 200px;
  }
}

nav.navbar {
  background: #fff;
  z-index: 20;
}

.nav-list {
  display: flex !important;
  flex-basis: auto;
  padding: 0;

  @media only screen and (max-width: 990px) {
    margin-left: 0 !important;
    height: 100%;
    position: fixed;
    display: block !important;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 10;

    .navbar-nav {
      padding: 0;
      width: 100%;

      .close-item {
        margin-bottom: 8px;
        svg {
          cursor: pointer;
        }
      }
      .join-network {
        text-align: left !important;
        padding: 15px 33px;
      }
      margin: 0;
      height: 100%;
      align-items: flex-start;
      float: right;
      div {
        margin-left: 0;
      }
      .close-item {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px #ddd solid;
        svg {
          margin-left: 0;
        }
      }
    }
  }
}

.navbar-nav {
  list-style: none;
  @media only screen and (max-width: 990px) {
    flex-direction: column;
    background-color: #f7f8fa;
  }
}

.aux-nav {
  background: #022241;
  height: 49px;
  position: relative;
  z-index: 22;
  @media only screen and (max-width: 990px) {
    display: none !important;
  }
}

.aux-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}

.aux-links li a {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  padding: 14px 20px 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  background-color: transparent;
  display: inline-block;
}

.aux-links li:hover {
  background-color: #00172b;
  input {
    background-color: #00172b;
  }
}

.aux-links li:first-child a {
  border-right: 0;
}

.aux-links a span {
  padding-left: 15px;
}

header {
  position: fixed;
  top: -56px;
  left: 0;
  width: 100%;
  z-index: 20;
  transition: all 0.5s ease-in-out;
}

header.fixed {
  top: -168px;
}

header.attop {
  top: 0;
}

.header-spacer {
  height: 168px;
}

.site-search {
  background: #f7f8fa;
  position: absolute;
  width: 100%;
  top: -55px;
  z-index: 21;
  transition: all 0.5s ease-in-out;
  @media only screen and (max-width: 990px) {
    display: none !important;
  }
}

.site-search.show {
  top: 56px;
}

.site-search .form-inline {
  padding: 35px 0;
  position: relative;
}

.site-search input.form-control {
  font-size: 24px;
  line-height: 28px;
  color: #7c8087;
  width: 100%;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #e3e7ed;
}

.site-search .btn {
  position: absolute;
  height: 1px;
  width: 1px;
  color: transparent;
  background-color: transparent;
  padding: 0;
  right: 0;
}

.site-search .btn:hover {
  background-color: transparent;
}

.search-close {
  position: absolute;
  right: 0;
  padding: 12px 12px 16px;
}

.dropdown-menu.mega-menu .container {
  background-color: #f7f8fa;
  padding: 45px 53px;
}

.mega-parent .nav-link::before {
  width: 82%;
}

.nav-item.dropdown.mega-parent:hover .mega-menu {
  display: block;
}

.mega-parent .dropdown-toggle::after,
.mega-parent-after .dropdown-toggle::after {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-left: 0.85em;
  vertical-align: 0.255em;
  content: '';
  border-bottom: 1px solid #022241;
  border-left: 1px solid #022241;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  @media only screen and (max-width: 990px) {
    vertical-align: 0.2em;
    transform: rotate(-135deg);
  }
}

.menu-card {
  background-color: #fff;
  width: 100%;
  margin: 7.5px;
  display: inline-block;
  padding: 20px 40px 20px 30px;
  border-radius: 5px;
  transition: all 0.3s linear;

  @media only screen and (max-width: 990px) {
    margin: 7.5px 0;
  }
}

.menu-card:hover {
  cursor: pointer;
  box-shadow: 0 10px 40px rgba(2, 34, 65, 0.25);
}

.menu-card a:hover {
  text-decoration: none;
}

.card-title {
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #022241;
  height: 83px;
  padding-left: 96px;
  position: relative;
}

.card-title img {
  position: absolute;
  left: -50px;
  top: -25px;
  max-width: 150px;
}

.card-title span {
  display: inline-block;
}

.card-desc {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #7c8087;
}

.search-item {
  display: flex;
  align-items: center;
  font-size: 12px;

  &:hover {
    .nav-search {
      background-color: #00172b;
    }
  }

  :global(.nav-search) {
    margin-left: 10px;
    background-color: rgb(2, 34, 65);
    color: white;
    border: none;
    padding: 10px 0;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: #00172b;
    }
    &.placeholder {
      color: white;
      opacity: 1;
    }
    &:before {
      content: '';
      height: 2px;
      width: 100%;
      background-color: white;
      display: block;
      position: relative;
      bottom: -22px;
      transform: scaleX(0);
      transform-origin: right center;
      transition: transform 0.5s cubic-bezier(0.694, 0, 0.335, 1);
    }
  }
}

.nav-search {
  margin-left: 10px;
  background-color: rgb(2, 34, 65);
  color: white;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #00172b;
  }
  &.placeholder {
    color: white;
    opacity: 1;
  }
  &:before {
    content: '';
    height: 2px;
    width: 100%;
    background-color: white;
    display: block;
    position: relative;
    bottom: -22px;
    transform: scaleX(0);
    transform-origin: right center;
    transition: transform 0.5s cubic-bezier(0.694, 0, 0.335, 1);
  }
}

.search-wrapper {
  position: relative;
  padding: 0 20px;
  svg {
    color: white;
  }
}

.nav-button-container {
  display: none;
  width: 100%;

  @media only screen and (max-width: 990px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > div {
      width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    svg {
      margin-left: 15px;
      cursor: pointer;
    }
  }
}

.logo-wrap {
  @media only screen and (max-width: 990px) {
    height: 80px;
    padding: 10px 0;
  }
}

.mobile-search {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f7f8fa;
  input {
    border: none;
    &:focus {
      outline: none;
    }
  }
  @media only screen and (min-width: 991px) {
    display: none;
  }

  :global(.mobile-search-close) {
    float: right;
  }
  svg {
    cursor: pointer;
  }
}
